import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import { ContainerSVGT, MainFont, ContactColor } from '../components/Sampler'
import ContactForm from '../components/ContactForm'

export const ContactPageTemplate = ({
  backgroundimage,
}) => (
  <div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${!!backgroundimage.childImageSharp ? backgroundimage.childImageSharp.fluid.src : backgroundimage
          })`,
      }}
    >

      {/* Font Imports */}
      <link
        href="https://fonts.googleapis.com/css2?family=IM+Fell+English&display=swap"
        rel="stylesheet"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
        rel="stylesheet"
      ></link>
      <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;700;900&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.cdnfonts.com/css/bahnschrift" rel="stylesheet"></link>

      {/* CONTACT IMAGE HEADING */}
      <div style={{ display: 'grid' }}>

        {/* Colored Style Container */}
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          height="310px" viewBox="0 0 1563.000000 1525.000000"
          preserveAspectRatio="xMidYMid meet"
          style={{
            gridRow: '1 / 1',
            gridColumn: '1 / 1',
          }}>

          <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
            fill={ContactColor} stroke="none">
            <path d={ContainerSVGT} />
          </g>
        </svg>

        {/* "Contact" Text */}
        <div style={{
          gridRow: '1 / 1',
          gridColumn: '1 / 1',
          alignSelf: 'center',
          textAlign: 'center',
          fontSize: '3.2em',
          color: '#FFFFFF',
          fontFamily: MainFont,
          fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
          letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
        }}>
          CONTACT
        </div>
      </div>
    </div>
    <section className="section">
      <div className="container">

        {/* Contact Page Explanation - ONLY FOR SITE SAMPLER */}
        <div style={{
          fontSize: '1.1em',
          lineHeight: '2em',
          letterSpacing: '0.06em',
          fontFamily: 'Poppins',
          marginTop: '8px',
          marginBottom: '30px',
          textAlign: 'center',
        }}>
          <i>This section of your website lets viewers reach out to you. The contact form links to your email, and you'll get all the information they put in here sent straight to you!</i>
        </div>
        <div style={{
          fontSize: '.9em',
          lineHeight: '2em',
          letterSpacing: '0.06em',
          fontFamily: 'Poppins',
          // color: ContactColor,
          // display: 'flex',
          // flexDirection: 'column',
          // marginLeft: '25%',
          // marginRight: '25%',
          marginBottom: '50px',
          textAlign: 'center',
        }}>
          <i>(This explanatory text will not appear on your website, only the form below. Don't use this sample to contact Church Sites Online. Use the form on the <a href="https://churchsites.online/" target="_blank">Church Sites Online home page</a> for that, instead.)</i>
        </div>
        

        <div className="content">
          <ContactForm />
        </div>
      </div>
    </section>
    
  </div>
)

ContactPageTemplate.propTypes = {
  backgroundimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ContactPageTemplate
        backgroundimage={frontmatter.backgroundimage}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}


export default ContactPage

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        backgroundimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`